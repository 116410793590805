/** @format */

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  id: "",
  username: "",
  first_name: "",
  last_name: "",
  contact: "",
  email: "",
  avatar: "",
  authorized: false,
  token: "",
};

export const userSlice = createSlice({
  name: "userData",
  initialState: initialState,
  reducers: {
    setUserData: (state, action) => {
      const updatedState = { ...state, ...action.payload };
      return updatedState;
    },
  },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;
