/** @format */

import {createSlice} from "@reduxjs/toolkit"
const initialState = [
  {
    id: 1,
    code: "INR",
    name: "Rupee",
    symbol: "₹",
  },
]

export const currencySlice = createSlice({
  name: "currencyList",
  initialState: initialState,
  reducers: {
    setCurrencyList: (state, action) => {
      const updatedState = action.payload
      return updatedState
    },
  },
})

export const {setCurrencyList} = currencySlice.actions
export default currencySlice.reducer
