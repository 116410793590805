/** @format */

import React from "react"
import GlobalRoutes from "./routes"
import {ConfigProvider} from "antd"

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#444ce7",
        },
      }}>
      <GlobalRoutes />
    </ConfigProvider>
  )
}

export default App
