/** @format */

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  globalLoading: false,
  routerAnimation: "slide-fadein-up",
};

export const globalDataSlice = createSlice({
  name: "globalData",
  initialState: initialState,
  reducers: {
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
  },
});

export const { setGlobalLoading } = globalDataSlice.actions;
export default globalDataSlice.reducer;
