/** @format */
import {configureStore} from "@reduxjs/toolkit"
import globalDataReducer from "./slices/globalDataSlice"
import userDataReducer from "./slices/userSlice"
import createSagaMiddleware from "redux-saga"
import rootSaga from "./sagas/rootSaga"
import workspaceReducer from "./slices/workspaceSlice"
import globalCounterReducer from "./slices/globalCounterSlice"
import currencyReducer from "./slices/currencyListSlice"

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    userData: userDataReducer,
    globalData: globalDataReducer,
    workSpaceData: workspaceReducer,
    globalCounterData: globalCounterReducer,
    currencyList: currencyReducer,
  },
  middleware: [sagaMiddleware],
})

sagaMiddleware.run(rootSaga)
