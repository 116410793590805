/** @format */

import React from "react";
import { Result, Button } from "antd";
import { SmileOutlined } from "@ant-design/icons";

const MobileView = () => {
  return (
    <div className="d-flex justify-content-center mt-4 p-4">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <Result
          icon={<SmileOutlined />}
          title={
            <div className="th-grey-2 th-14 th-fw-600 pb-3">
              Coming soon for mobile devices too !
            </div>
          }
        />

        <div className="d-flex justify-content-center align-items-center gap-2">
          <div className="pr-3 text-center th-grey-2 th-fw-600 th-20">
            Please switch to desktop mode for better experience for now.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
