/** @format */

import {lazy, Suspense} from "react"
import {Navigate, Route, Routes} from "react-router-dom"
import Loader from "../components/globalLoader"
import {useSelector} from "react-redux"
import {CSSTransition} from "react-transition-group"

const routes = [
  {
    path: "/home",
    exact: true,
    Component: lazy(() => import("../pages/home")),
  },
  {
    path: "/profile",
    exact: true,
    Component: lazy(() => import("../pages/profile")),
  },
  {
    path: "/auth/login",
    exact: true,
    Component: lazy(() => import("../pages/auth/login")),
  },
  {
    path: "/auth/signup",
    exact: true,
    Component: lazy(() => import("../pages/auth/signup")),
  },
  {
    path: "/auth/forgot-password",
    exact: true,
    Component: lazy(() => import("../pages/auth/forgotPassword")),
  },
  {
    path: "/auth/404",
    exact: true,
    Component: lazy(() => import("../components/pageNotFound")),
  },
  {
    path: "/auth/verify",
    exact: true,
    Component: lazy(() => import("../pages/auth/verify")),
  },
  {
    path: "/:workspace/home",
    exact: true,
    Component: lazy(() => import("../pages/home")),
  },
  {
    path: "/:workspace/profile",
    exact: true,
    Component: lazy(() => import("../pages/profile")),
  },
  // Projects
  {
    path: "/:workspace/projects",
    exact: true,
    Component: lazy(() => import("../pages/projects")),
  },
  {
    path: "/:workspace/projects/:id",
    exact: true,
    Component: lazy(() => import("../pages/projects")),
  },
  {
    path: "/projects",
    exact: true,
    Component: lazy(() => import("../pages/projects")),
  },
  {
    path: "/:workspace/projects/:Id",
    exact: true,
    Component: lazy(() => import("../pages/projects/project")),
  },
  {
    path: "/projects/:Id",
    exact: true,
    Component: lazy(() => import("../pages/projects/project")),
  },
  {
    path: "/projects/:Id/review-sign",
    exact: true,
    Component: lazy(() => import("../pages/projects/reviewSignContract")),
  },
  {
    path: "/projects/:projectId/tasks/:taskId",
    exact: true,
    Component: lazy(() => import("../pages/projects/tasks")),
  },
  // Payments, Invoices
  {
    path: "/Payments",
    exact: true,
    Component: lazy(() => import("../pages/payments")),
  },
  {
    path: "/:workspace/payments",
    exact: true,
    Component: lazy(() => import("../pages/payments")),
  },
  {
    path: "/:workspace/payments/create-invoice",
    exact: true,
    Component: lazy(() => import("../pages/payments/createInvoice")),
  },
  {
    path: "/payments/create-invoice",
    exact: true,
    Component: lazy(() => import("../pages/payments/createInvoice")),
  },
  {
    path: "/:workspace/payments/:invoiceId",
    exact: true,
    Component: lazy(() => import("../pages/payments/viewInvoice")),
  },
  {
    path: "/payments/:invoiceId",
    exact: true,
    Component: lazy(() => import("../pages/payments/viewInvoice")),
  },
  // Proposals
  {
    path: "/:workspace/proposals",
    exact: true,
    Component: lazy(() => import("../pages/proposals")),
  },
  {
    path: "/proposals",
    exact: true,
    Component: lazy(() => import("../pages/proposals")),
  },
  {
    path: "/proposals/:Id",
    exact: true,
    Component: lazy(() => import("../pages/proposals/proposal")),
  },
  // Team
  {
    path: "/:workspace/team",
    exact: true,
    Component: lazy(() => import("../pages/team")),
  },
  // Clients
  {
    path: "/:workspace/clients",
    exact: true,
    Component: lazy(() => import("../pages/clients")),
  },
  {
    path: "/clients",
    exact: true,
    Component: lazy(() => import("../pages/clients")),
  },
  // Settings
  {
    path: "/:workspace/settings",
    exact: true,
    Component: lazy(() => import("../pages/settings")),
  },
  {
    path: "/settings",
    exact: true,
    Component: lazy(() => import("../pages/settings")),
  },
  // Home
  // Inbox
  {
    path: "/inbox",
    exact: true,
    Component: lazy(() => import("../pages/inbox")),
  },
  {
    path: "/:workspace/inbox",
    exact: true,
    Component: lazy(() => import("../pages/inbox")),
  },
  // WorkSpace
  {
    path: "/:workspace/workspace",
    exact: true,
    Component: lazy(() => import("../pages/workspace")),
  },
  {
    path: "/workspace",
    exact: true,
    Component: lazy(() => import("../pages/workspace")),
  },
  {
    path: "/:workspace/workspace/create-portfolio",
    exact: true,
    Component: lazy(() =>
      import("../pages/workspace/PortFolio/CreatePortfolio"),
    ),
  },
  {
    path: "/:workspace/workspace/create-portfolio/:editId",
    exact: true,
    Component: lazy(() =>
      import("../pages/workspace/PortFolio/CreatePortfolio"),
    ),
  },

  // NETWORK
  {
    path: "/network",
    exact: true,
    Component: lazy(() => import("../pages/network")),
  },
  // TRANSACTION
  {
    path: "/transactions",
    exact: true,
    Component: lazy(() => import("../pages/transactions")),
  },
  // PAYSLIPS
  {
    path: "/:workspace/payments/payslip/:payslipId",
    exact: true,
    Component: lazy(() => import("../pages/payments/viewPayslip")),
  },
  // OPPORTUNITY
  // {
  //   path: "/opportunities",
  //   exact: true,
  //   Component: lazy(() => import("../pages/opportunity")),
  // },
  // {
  //   path: "/opportunities/:Id",
  //   exact: true,
  //   Component: lazy(() => import("../pages/opportunity/opportunity")),
  // },
  {
    path: "/create-opportunity",
    exact: true,
    Component: lazy(() => import("../pages/opportunity/createOpportunity")),
  },
  // DISCOVER
  {
    path: "/discover",
    exact: true,
    Component: lazy(() => import("../pages/discover")),
  },
  // PAYROLL
  {
    path: "/payroll",
    exact: true,
    Component: lazy(() => import("../pages/payroll")),
  },
  {
    path: "/:workspace/payroll",
    exact: true,
    Component: lazy(() => import("../pages/payroll")),
  },
]

const GlobalRoutes = () => {
  const globalRouterAnimation = useSelector(
    state => state.globalData?.routerAnimation,
  )
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/home" replace />} />
      {routes.map(({path, Component, exact}) => (
        <Route
          path={path}
          key={path}
          exact={exact}
          element={
            <CSSTransition
              key={path}
              appear
              classNames={globalRouterAnimation}
              timeout={globalRouterAnimation === "none" ? 0 : 300}>
              <Suspense fallback={<Loader />}>
                <Component />
              </Suspense>
            </CSSTransition>
          }
        />
      ))}
      <Route exact path="*" element={<Navigate to="/auth/404" replace />} />
    </Routes>
  )
}

export default GlobalRoutes
