/** @format */

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  counter: {},
};

export const globalCounterSlice = createSlice({
  name: "globalCounterDetails",
  initialState: initialState,
  reducers: {
    setCounterDetails: (state, action) => {
      state.counter = action.payload;
    },
  },
});

export const { setCounterDetails } = globalCounterSlice.actions;
export default globalCounterSlice.reducer;
