/** @format */

import { createSlice } from "@reduxjs/toolkit";
const initialState = sessionStorage?.getItem("current_workspace")
  ? JSON.parse(sessionStorage.getItem("current_workspace"))
  : {};

export const workspaceSlice = createSlice({
  name: "workSpace",
  initialState: initialState,
  reducers: {
    setCurrentWorkspace: (state, action) => {
      const updatedState = { ...state, ...action.payload };
      return updatedState;
    },
  },
});

export const { setCurrentWorkspace } = workspaceSlice.actions;
export default workspaceSlice.reducer;
