/** @format */

import {Avatar, Image} from "antd"
import {EyeFilled, UserOutlined} from "@ant-design/icons"
import {useSelector} from "react-redux"
import {MULTI_USER_ICON, USER_ICON} from "../../utils/commonSVG"

export const UserMenusImage = ({size, color = "#293056", className}) => {
  const currentUser = useSelector(state => state?.userData)
  return (
    <>
      {currentUser?.avatar ? (
        <img
          src={currentUser?.avatar}
          alt="icon"
          style={{
            width: size ?? 16,
            height: size ?? 16,
            borderRadius: size ? 8 : 0,
            objectFit: "cover",
          }}
        />
      ) : (
        <div
          style={{
            width: size ?? 16,
            height: size ?? 16,
          }}
          className={`d-flex align-items-center justify-content-center th-bg-white ${className}`}>
          <USER_ICON size={24} color={color} />
        </div>
      )}
    </>
  )
}

export const UserImage = ({size, color, borderRadius, className}) => {
  const currentUser = useSelector(state => state?.userData)
  return (
    <>
      {currentUser?.avatar ? (
        <img
          src={currentUser?.avatar}
          alt="icon"
          style={{
            width: size ?? 16,
            height: size ?? 16,
            borderRadius: borderRadius ?? 8,
            objectFit: "cover",
          }}
        />
      ) : (
        <div
          style={{
            width: size ?? 16,
            height: size ?? 16,
          }}
          className={`d-flex align-items-center justify-content-center ${className}`}>
          <USER_ICON size={size ?? 16} color={color} />
        </div>
      )}
    </>
  )
}
export const ListUserImage = ({
  size,
  image,
  borderRadius,
  color,
  className,
  showPreview = false,
}) => {
  return (
    <>
      {image ? (
        <Image
          src={image}
          alt="icon"
          preview={
            showPreview
              ? {
                  mask: <EyeFilled />,
                  previewText: null,
                }
              : false
          }
          style={{
            width: size ?? 16,
            height: size ?? 16,
            objectFit: "cover",
            borderRadius: borderRadius ?? 0,
          }}
        />
      ) : (
        <div
          style={{
            width: size ?? 16,
            height: size ?? 16,
          }}
          className={`d-flex align-items-center justify-content-center th-bg-white ${className}`}>
          <USER_ICON size={size ?? 24} color={color} />
        </div>
      )}
    </>
  )
}

export const WorkspaceLogo = ({logoSize}) => {
  const currentWorkspace = useSelector(state => state?.workSpaceData)

  return (
    <>
      {currentWorkspace?.image ? (
        <img
          src={currentWorkspace?.image}
          alt="icon"
          style={{
            height: logoSize ?? 16,
            width: logoSize ?? 16,
            borderRadius: 4,
            objectFit: "cover",
          }}
        />
      ) : (
        <MULTI_USER_ICON size={logoSize ?? 32} />
      )}
    </>
  )
}

export const MultiUserLogo = ({size, color, className}) => {
  const currentWorkspace = useSelector(state => state?.workSpaceData)

  return (
    <>
      {currentWorkspace?.image ? (
        <img
          src={currentWorkspace?.image}
          alt="icon"
          style={{
            height: size ?? 16,
            width: size ?? 16,
            borderRadius: 4,
            objectFit: "cover",
          }}
        />
      ) : (
        <div
          style={{
            width: size ?? 16,
            height: size ?? 16,
          }}
          className={`d-flex align-items-center justify-content-center th-bg-white ${className}`}>
          <MULTI_USER_ICON size={32} color={color} />
        </div>
      )}
    </>
  )
}

export const AllWorkSpaceLogo = ({size, isPersonal, image, borderRadius}) => {
  return isPersonal ? (
    image ? (
      <div
        style={{
          background: `url(${image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: size ?? 16,
          width: size ?? 16,
          borderRadius: borderRadius ?? 8,
          objectFit: "cover",
        }}
      />
    ) : (
      <div
        style={{
          height: size ?? 16,
          width: size ?? 16,
          borderRadius: borderRadius ?? 8,
          display: "flex",
          border: "1px solid #EAECF5",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <USER_ICON size={size / 2} />
      </div>
    )
  ) : image ? (
    <div
      style={{
        background: `url(${image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: size ?? 16,
        width: size ?? 16,
        borderRadius: borderRadius ?? 8,
        objectFit: "cover",
      }}
    />
  ) : (
    <div
      style={{
        height: size ?? 16,
        width: size ?? 16,
        borderRadius: borderRadius ?? 8,
        display: "flex",
        border: "1px solid #EAECF5",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <MULTI_USER_ICON size={size / 2} />
    </div>
  )
}
