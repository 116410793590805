/** @format */
import "./index.scss"

const Loader = () => {
  return (
    <div className="d-flex justify-content-center th-globalLoader">
      <div
        className="loader position-absolute"
        style={{
          top: "45%",
        }}>
        <div className="outer"></div>
        <div className="middle"></div>
        <div className="inner"></div>
      </div>
    </div>
  )
}

export default Loader
