/** @format */

// eslint-disable-next-line no-unused-vars
import React from "react";

const AuthLayout = ({ children }) => {
  return children;
};

export default AuthLayout;
