/** @format */

export const personalSpaceSubMenu = [
  {
    name: "Home",
    url: "/home",
  },
  {
    name: "Profile",
    url: "/profile",
  },
  {
    name: "Clients",
    url: "/clients",
  },
  {
    name: "MANAGE",
    url: null,
  },
  {
    name: "Projects",
    url: "/projects",
  },
  {
    name: "Payments",
    url: "/payments",
  },
  {
    name: "Proposals",
    url: "/proposals",
  },
]
export const commonWorkSpaceSubMenu = [
  {
    name: "Home",
    url: "/home",
  },
  {
    name: "Workspace Page",
    url: "/workspace",
  },
  {
    name: "MANAGE",
    url: null,
  },
  {
    name: "Projects",
    url: "/projects",
  },
  {
    name: "Payments",
    url: "/payments",
  },

  {
    name: "Proposals",
    url: "/proposals",
  },

  {
    name: "ADMIN",
    url: "",
  },
  {
    name: "Payroll",
    url: "/payroll",
  },
  {
    name: "Team",
    url: "/team",
  },
  {
    name: "Clients",
    url: "/clients",
  },
]
export const clientMenu = [
  {
    name: "Home",
    url: "/home",
  },
  {
    name: "Network",
    url: "/network",
  },
  {
    name: "MANAGE",
    url: "",
  },
  {
    name: "Projects",
    url: "/projects",
  },
  {
    name: "Payments",
    url: "/payments",
  },
  {
    name: "Proposals",
    url: "/proposals",
  },
  {
    name: "HIRE EXPERTS",
    url: "",
  },

  // {
  //   name: "Opportunities",
  //   url: "/opportunities",
  // },
  {
    name: "Discover talent",
    url: "/discover",
  },
]
