/** @format */

import React, {Fragment} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import AuthLayout from "./AuthLayout"
import MainLayout from "./MainLayout"
import {useSelector, useDispatch} from "react-redux"
import MyDesktop from "../components/deviceViews/MyDesktop"
import MyMobile from "../components/deviceViews/MyMobile"
import MobileView from "../components/mobileView"
import Loader from "../components/globalLoader"
import {setUserData} from "../store/slices/userSlice"

const Layouts = {
  auth: AuthLayout,
  main: MainLayout,
}

const Layout = ({children}) => {
  const isUserAuthorized = useSelector(state => state.userData?.authorized)
  const globalLoading = useSelector(state => state.globalData?.globalLoading)
  const currentUser = useSelector(state => state.userData)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pathname = location.pathname
  const queryParams = new URLSearchParams(window.location.search)
  const map_token = queryParams.get("token")
  const isNewuser = queryParams.get("is_new")
  const isClient = queryParams.get("client")
  const email = queryParams.get("email")
  const proposalID = queryParams.get("proposal_id")
  const projectID = queryParams.get("project")
  const redirectionWorkspace = queryParams.get("workspace")

  const handleRedirection = ({pathname, stateObj = {}}) => {
    navigate(pathname, {
      state: {
        ...stateObj,
      },
    })
  }
  // Layout Rendering
  const getLayout = () => {
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return "auth"
    }
    return "main"
  }
  const Container = Layouts[getLayout()]
  let isAuthLayout = getLayout() === "auth"
  const BootstrappedLayout = () => {
    if (globalLoading) {
      return <Loader />
    }
    if (proposalID) {
      localStorage.setItem("proposal_id", proposalID)
    }
    if (projectID) {
      localStorage.setItem("project_id", projectID)
    }
    if (isClient === "true") {
      localStorage.setItem("is_client_login", isClient)
    }
    if (redirectionWorkspace) {
      localStorage.setItem("redirection_workspace", redirectionWorkspace)
    }
    if ((proposalID || projectID) && !map_token) {
      if (currentUser?.email === email) {
        if (proposalID) {
          window.location.href = `/proposals/${proposalID}`
        } else if (projectID) {
          window.location.href = `/projects/${projectID}`
        }
      } else {
        isAuthLayout = true
        localStorage.setItem("email", email)
        return handleRedirection({
          pathname: `/auth/login/`,
          stateObj: {email: email},
        })
      }
    }
    if (map_token) {
      if (currentUser?.email === email) {
        if (isClient == "true") {
          return handleRedirection({
            pathname: "/auth/verify",
            stateObj: {map_token: map_token},
          })
        } else {
          return handleRedirection({
            pathname: `/auth/login/`,
            stateObj: {map_token: map_token},
          })
        }
      } else {
        isAuthLayout = true
        localStorage.setItem("map_token", map_token)
        if (isClient === "true") {
          return handleRedirection({
            pathname: "/auth/verify",
          })
        } else {
          localStorage.setItem("email", email)

          if (isNewuser === "false") {
            return handleRedirection({
              pathname: `/auth/login/`,
              stateObj: {email: email},
            })
          } else {
            return handleRedirection({
              pathname: "/auth/signup",
              stateObj: {email: email},
            })
          }
        }
      }
    } else {
      if (!isAuthLayout && !isUserAuthorized) {
        return handleRedirection({pathname: "/auth/login"})
      }

      return <Container>{children}</Container>
    }
  }

  return (
    <Fragment>
      <MyMobile>
        <MobileView />
      </MyMobile>
      <MyDesktop>{BootstrappedLayout()}</MyDesktop>
    </Fragment>
  )
}

export default Layout
