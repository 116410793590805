/** @format */

import ReactDOM from "react-dom/client";
import App from "./App";
import "./assests/styles/global.scss";
import "./assests/styles/antd.scss";
import "./assests/styles/common.scss";
import "./assests/styles/measurements.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./layout";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import axios from "axios";
import { BASE_URL } from "./utils/apiConfig";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Authorization"] = localStorage["token"]
  ? `Bearer ${localStorage.getItem("token")}`
  : "";

// axios.interceptors.request.use(
//   (config) => {
//     config.params = {
//       ...(config.params || {}),
//       workspace: JSON.parse(sessionStorage.getItem("current_workspace"))?.id,
//     };

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Layout>
        <App />
      </Layout>
    </Provider>
  </BrowserRouter>
);
