/** @format */

import React, {useState} from "react"
import Menu from "../menu"
import {useSelector} from "react-redux"

const MainLayout = ({children}) => {
  const currentUser = useSelector(state => state?.userData)
  const [subMenuCollapsed, setSubMenuCollapsed] = useState(false)
  const handleSubMenuCollapse = data => {
    setSubMenuCollapsed(data)
  }
  return (
    <>
      <main className="th-bg-white">
        <div className="d-flex">
          <div className="text-center th-menu">
            <Menu
              subMenuCollapsed={subMenuCollapsed}
              handleSubMenuCollapse={handleSubMenuCollapse}
            />
          </div>

          <div className="th-scrollbar-hidden" style={{width: "100%"}}>
            {children}
          </div>
        </div>
      </main>
    </>
  )
}

export default MainLayout
