/** @format */

import axios from "axios"

const BASE_URL =
  window.location.host === "app.cogig.in"
    ? "https://api.cogig.in/apiV1/"
    : "https://cogigapi.trixno.com/apiV1/"

const LINKS_BASE_URL = "www.cogig.in"

export {BASE_URL, LINKS_BASE_URL}

export const authFetcher = async url => {
  try {
    const {data} = await axios.get(url)
    return data
  } catch (error) {
    throw error
  }
}
